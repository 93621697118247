import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, get, type SubmitHandler, useForm } from 'react-hook-form';
import Select from 'react-select';

import { EnumsUserSettingsGroupKey } from '@lp-lib/api-service-client/public';

import { useAnalytics } from '../../analytics/AnalyticsContext';
import { useUserSettingsAnalytics } from '../../analytics/userSettings';
import {
  findLanguageOptionOrDefault,
  LanguageAllOptions,
  type LanguageOption,
} from '../../i18n/language-options';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import {
  ClosedCaptionsAnalytics,
  useGetSharedTrackingProps,
} from '../MediaControls/ClosedCaptionsAnalytics';
import { SwitcherHookForm } from '../Switcher';
import { useUser } from '../UserContext';
import { useI18nSettings } from './useI18nSettings';

export const Language = (): JSX.Element => {
  const user = useUser();

  if (!user.id) return <></>;

  return (
    <div className='w-full flex flex-col gap-7.5'>
      <section className='w-130'>
        <LanguageSettings />
      </section>
    </div>
  );
};

interface I18NSettingsFormData {
  voiceOverLocale: LanguageOption;
  subtitlesLocale: LanguageOption;
  subtitles: boolean;
}

function LanguageSettings(): JSX.Element {
  const userSettingsAnalytics = useUserSettingsAnalytics();

  const formRef = useRef<null | HTMLFormElement>(null);
  const styles = useMemo(() => buildReactSelectStyles<Option<string>>(), []);

  const { i18nSettings, update, isLoading } = useI18nSettings();
  const { register, handleSubmit, control, watch } =
    useForm<I18NSettingsFormData>({
      defaultValues: {
        subtitles: i18nSettings?.value?.subtitles ?? false,
        subtitlesLocale: findLanguageOptionOrDefault(
          i18nSettings?.value?.subtitlesLocale
        ),
        voiceOverLocale: findLanguageOptionOrDefault(
          i18nSettings?.value?.voiceOverLocale
        ),
      },
    });

  const analytics = useAnalytics();
  const [ccAnalytics] = useState(() => new ClosedCaptionsAnalytics(analytics));
  const getAnalyticsExtra = useGetSharedTrackingProps();

  useEffect(() => {
    const { unsubscribe } = watch((values, info) => {
      if (!info.name) return;
      const value: unknown = get(values, info.name, undefined);

      userSettingsAnalytics.trackUserSettingsModified({
        groupKey: EnumsUserSettingsGroupKey.UserSettingsGroupKeyI18N,
        field: info.name,
        value,
      });

      switch (info.name) {
        case 'subtitles':
          ccAnalytics.trackClosedCaptionsClicked(
            Boolean(value),
            getAnalyticsExtra()
          );
          break;
        case 'subtitlesLocale':
          const option: Option<string> = value as Option<string>;
          ccAnalytics.trackClosedCaptionsLanguageClicked(
            option.value,
            findLanguageOptionOrDefault(option.value).label,
            getAnalyticsExtra()
          );
          break;
      }

      formRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    });
    return () => unsubscribe();
  }, [ccAnalytics, getAnalyticsExtra, userSettingsAnalytics, watch]);

  const onSubmit: SubmitHandler<I18NSettingsFormData> = async (data) => {
    await update({
      subtitles: data.subtitles,
      subtitlesLocale: data.subtitlesLocale.value,
      // NOTE(drew): voiceOverLocale is not currently exposed, keep it in sync
      // with subtitlesLocale for now
      voiceOverLocale: data.subtitlesLocale.value,
    });
  };

  return (
    <div className='w-full text-white'>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col gap-8'
      >
        <label className='font-bold flex justify-between'>
          Subtitles{' '}
          <SwitcherHookForm
            defaultChecked={!!i18nSettings?.value?.subtitles}
            {...register('subtitles')}
          />
        </label>

        <label className='font-bold flex flex-col gap-1.5'>
          Subtitle Language
          <Controller<I18NSettingsFormData, 'subtitlesLocale'>
            name='subtitlesLocale'
            control={control}
            render={({ field }) => (
              <Select<Option<string>>
                {...field}
                name='subtitlesLocale'
                options={LanguageAllOptions}
                styles={styles}
                classNamePrefix='select-box-v2'
                isSearchable={false}
                isLoading={isLoading}
              />
            )}
          />
        </label>
      </form>
    </div>
  );
}

import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { releaseMediaStream, removeAllTracks } from '../../utils/media';
import { Modal } from '../common/Modal';
import { useDeviceAPI, useDeviceState } from '../Device';
import {
  DeviceCheck as DeviceCheckCore,
  DeviceCheckContextProvider,
  useDeviceCheckContext,
} from '../Device/Check';
import {
  useMustVideoEffectsMixer,
  useMustVirtualBackgroundMixer,
} from '../Device/video-stream-mixer';
import { VBGSettingsButton } from '../Device/VirtualBackgroundSettings';
import { useUserContext } from '../UserContext';
import { useLocalVideoEffectsSettingsStore } from '../VideoEffectsSettings/LocalSettings';
import { VideoEffectsSettingsDeviceCheckPanel } from '../VideoEffectsSettingsPanels/DeviceCheck';
import { VideoEffectsSettingsVenueSettingsPanel } from '../VideoEffectsSettingsPanels/Venue';
import { useCohostMixerEnabled } from './CohostPositionManagerProvider';

// The team asked to re-enable the editing feature.
function isDebugging() {
  // return getFeatureQueryParamArray('debug-tools') !== 'disabled';
  return true;
}

function DeviceCheckInternal(props: { handleClose?: () => void }) {
  const { updateUserStates } = useUserContext();
  const { videoMediaStream } = useDeviceCheckContext();
  const { activeVideoInputDeviceOption: did } = useDeviceState();

  const deviceAPI = useDeviceAPI();
  const mixer = deviceAPI.mixer;
  const vbgMixer = useMustVirtualBackgroundMixer(mixer);
  const vesMixer = useMustVideoEffectsMixer(mixer);
  const store = useLocalVideoEffectsSettingsStore();
  const [outputStream, setOutputMediaStream] = useState<MediaStream | null>(
    null
  );
  useLayoutEffect(() => {
    async function exec() {
      const inputTrack = videoMediaStream?.getVideoTracks()[0];
      await mixer.updateCameraTrack(inputTrack ?? null);
      // we only need the camera track (with virtual background) here, the
      // final rendering will be handled by VideoEffectsMediaElement.
      const outputTrack = vbgMixer.virtualBackgroundTrack;
      const s = new MediaStream();
      if (outputTrack) {
        s.addTrack(outputTrack);
      }
      setOutputMediaStream(s);
    }
    exec();
  }, [mixer, vbgMixer, videoMediaStream]);

  const handleContinue = useLiveCallback(async () => {
    await vesMixer.updateVideoEffectsSettings(store.settings);
    if (videoMediaStream && did?.value) {
      await deviceAPI.updateSingletonVideoTrack({
        action: 'update',
        input: {
          cameraTrack: videoMediaStream.getVideoTracks()[0],
          deviceId: did.value,
          clone: true,
        },
      });
    } else {
      deviceAPI.log.warn('update singleton video track failed', {
        hasVideoMediaStream: !!videoMediaStream,
        deviceId: did?.value,
      });
    }

    updateUserStates({ joined: true });
    props.handleClose?.();
  });

  return (
    <VideoEffectsSettingsDeviceCheckPanel
      mediaStream={outputStream}
      onContinue={handleContinue}
      title='Cohost Configuration Settings'
      stagePodiumSettings='stage'
      headerAccessory={
        vbgMixer.virtualBackgroundAvailable &&
        isDebugging() && (
          <VBGSettingsButton className='btn-warning min-w-36 min-h-10' />
        )
      }
      settingsEditable={isDebugging()}
    />
  );
}

export function CohostDeviceCheckModal(props: { handleClose?: () => void }) {
  return (
    <DeviceCheckContextProvider>
      <Modal className='w-11/12 h-5/6'>
        <div className={`w-full h-full p-8 overflow-auto scrollbar`}>
          <DeviceCheckInternal {...props} />
        </div>
      </Modal>
    </DeviceCheckContextProvider>
  );
}

export function CohostFullVESVenueSettingsPanel() {
  const deviceAPI = useDeviceAPI();
  const mixer = deviceAPI.mixer;
  const vbgMixer = useMustVirtualBackgroundMixer(mixer);
  const vesMixer = useMustVideoEffectsMixer(mixer);
  const [vesSettings, setVesSettings] = useState(
    vesMixer.getVideoEffectsSettings()
  );
  const [track, setTrack] = useState(vbgMixer.virtualBackgroundTrack);
  const outputStreamRef = useRef(new MediaStream());

  useEffect(() => {
    return vbgMixer.on('virtual-background-track-updated', (t) => setTrack(t));
  }, [vbgMixer]);

  useEffect(() => {
    releaseMediaStream(outputStreamRef.current);
    removeAllTracks(outputStreamRef.current);
    if (track) {
      // The underlying _VideoEffectsMediaElement_ will release the
      // UnplayableVideo when the component unmounted. We need to clone the
      // track here to avoid the track in mixer being released.
      outputStreamRef.current.addTrack(track.clone());
    }
  }, [track]);

  // Sync the video effects settings back to mixer
  useEffect(() => {
    return () => {
      vesMixer.updateVideoEffectsSettings(vesSettings);
    };
  }, [vesSettings, vesMixer]);

  return (
    <VideoEffectsSettingsVenueSettingsPanel
      mediaStream={outputStreamRef.current}
      initialSettings={vesSettings}
      title='Cohost Configuration Settings'
      stagePodiumSettings='stage'
      settingsEditable={true}
      headerAccessory={
        vbgMixer.virtualBackgroundAvailable && (
          <VBGSettingsButton className='btn-warning min-w-36 min-h-10' />
        )
      }
      onSave={async (values) => {
        setVesSettings(values);
      }}
    />
  );
}

export function CohostVESVenueSettingsPanel() {
  const mixerEnabled = useCohostMixerEnabled();
  if (mixerEnabled) return <CohostFullVESVenueSettingsPanel />;
  return <DeviceCheckCore layout='col' />;
}
